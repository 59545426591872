
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute, useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { event } from "vue-gtag";
import { AxiosRequestConfig } from "axios";

interface Subject {
  _id: string;
  name: string;
}

interface Document {
  _id: string;
  title: string;
  subject: Subject[];
  desc: string;
  createdAt: string;
  updatedAt: string;
  isPublic: boolean;
  files: string[];
  ispublic?: boolean;
  coverPicture?: { url: string; name: string };
  level: string[];
  photos: string[];
}

export default defineComponent({
  name: "document-details",
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const documentID = route.params.id;

    const document = ref<Document>({
      subject: [],
      _id: "",
      title: "",
      desc: "",
      createdAt: "",
      updatedAt: "",
      isPublic: false,
      files: [],
      coverPicture: undefined,
      level: [],
      photos: [],
    });

    const loading = ref(true);

    const levelMapping = {
      "-2": t("document.levels.niveauPre"),
      "-1": t("document.levels.niveau0"),
      "1": t("document.levels.niveau1"),
      "2": t("document.levels.niveau2"),
      "3": t("document.levels.niveau3"),
      "4": t("document.levels.niveau4"),
      "5": t("document.levels.niveau5"),
      "6": t("document.levels.niveau6"),
      "7": t("document.levels.niveau7"),
      "8": t("document.levels.niveau8"),
      "9": t("document.levels.niveau9"),
      "10": t("document.levels.niveau10"),
      "11": t("document.levels.niveau11"),
      "12": t("document.levels.niveau12"),
      "13": t("document.levels.niveau13"),
    };

    ApiService.get(`/library/item/${documentID}`)
      .then(({ data }) => {
        const files = data.files || [];
        const imageFiles = files
          .filter((f: string) =>
            ["png", "jpg", "jpeg", "gif"].includes(getFileIconName(f))
          )
          .map((f: string) => getFileLink(f));
        const otherFiles = files.filter(
          (f: string) =>
            !["png", "jpg", "jpeg", "gif"].includes(getFileIconName(f))
        );
        document.value = {
          subject: data.subject,
          _id: data._id,
          title: data.title,
          desc: data.description,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          isPublic: data.isPublic,
          files: otherFiles,
          photos: imageFiles,
          level: data.level,
          coverPicture: data.coverPicture
            ? { url: data.coverPicture, name: data.coverPictureName }
            : undefined,
        };
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        loading.value = false;
      });

    const confirmDelete = () => {
      Swal.fire({
        title: t("document.confirmDeleteDocument"),
        text: t("document.deleteDocumentWarn"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("document.acceptDelete"),
        cancelButtonText: t("document.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          event("Delete document", {
            event_category: "Document",
            event_label: "Document section",
            value: 1,
          });

          ApiService.patch(`library/item/archive/${documentID}`, {
            status: "inactive",
          } as AxiosRequestConfig)

            .then(() => {
              Swal.fire(
                t("document.deleted"),
                t("document.deletedDocumentInfo"),
                "success"
              ).then(() => {
                router.push(`/library`);
              });
            })
            .catch((e) => console.log(e));
        }
      });
    };

    const getFileIconName = (file: string): string => {
      const fileName = file.split("/").pop()?.split("?")[0] || "";
      const type = fileName.split(".").pop()?.toLowerCase() || "";

      const supportedTypes: string[] = [
        "pdf",
        "png",
        "jpg",
        "jpeg",
        "ppt",
        "xls",
        "txt",
        "gif",
        "doc",
        "pptx",
        "xlsx",
        "docx",
        "zip",
        "mkv",
        "avi",
        "mp4",
        "mp3",
      ];

      return supportedTypes.includes(type) ? type : "file";
    };

    const getFileLink = (file: string): string => {
      if (file.startsWith("http")) {
        return file;
      }

      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    const getShortFileName = (file: string): string => {
      const decodedFile = decodeURIComponent(file);

      const segments = decodedFile.split("-");

      const fileName = segments.length > 1 ? segments.pop() : decodedFile;
      return fileName || "";
    };

    const getFileName = (file: string): string => {
      try {
        const decodedFile = decodeURIComponent(file);

        // Split the path by "/"
        const segments = decodedFile.split("/");

        // Get the last part (filename)
        const fullFileName = segments.pop() || decodedFile;

        // Remove any numeric prefix (e.g., "1234-filename.txt" -> "filename.txt")
        const fileNameWithoutPrefix = fullFileName.replace(/^\d+-/, "");

        // Remove the file extension (e.g., "filename.txt" -> "filename")
        return fileNameWithoutPrefix.replace(/\.[^.]+$/, "");
      } catch (error) {
        console.error("Error decoding file name:", error);
        return file;
      }
    };

    const getLevelNames = (level: string) => {
      return levelMapping[level];
    };

    return {
      t,
      locale,
      documentID,
      document,
      confirmDelete,
      moment,
      getFileIconName,
      getFileLink,
      getShortFileName,
      getFileName,
      getLevelNames,
      loading,
    };
  },
});
