import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5959c39c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card card-flush pt-3 mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title d-flex align-items-center" }
const _hoisted_4 = { class: "svg-icon svg-icon-2x fw-boldest svg-icon-dark" }
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  key: 0,
  class: "card-toolbar"
}
const _hoisted_7 = { class: "card-body pt-3" }
const _hoisted_8 = { class: "mb-10" }
const _hoisted_9 = { class: "d-flex flex-wrap py-5" }
const _hoisted_10 = { class: "flex-equal me-5" }
const _hoisted_11 = {
  class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0",
  style: {"table-layout":"fixed","width":"100%"}
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "text-gray-400 w-250px" }
const _hoisted_14 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_15 = { class: "text-gray-800" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "text-gray-400 w-250px" }
const _hoisted_18 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_19 = { class: "text-gray-800" }
const _hoisted_20 = { class: "text-gray-400" }
const _hoisted_21 = { class: "svg-icon svg-icon-1 svg-icon-warning" }
const _hoisted_22 = { class: "subject-tags" }
const _hoisted_23 = { class: "text-gray-400" }
const _hoisted_24 = { class: "svg-icon svg-icon-1 svg-icon-warning" }
const _hoisted_25 = { class: "subject-tags" }
const _hoisted_26 = { class: "text-gray-400" }
const _hoisted_27 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_28 = { class: "badge-light-primary badge fs-7 px-5 fw-bolder text-gray-400 rounded-pill" }
const _hoisted_29 = { class: "mt-10" }
const _hoisted_30 = { class: "text-gray-400" }
const _hoisted_31 = { class: "svg-icon svg-icon-1 svg-icon-warning" }
const _hoisted_32 = { colspan: "2" }
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = { key: 2 }
const _hoisted_35 = {
  colspan: "2",
  class: "text-gray-400 pb-5"
}
const _hoisted_36 = { class: "svg-icon svg-icon-1 svg-icon-info" }
const _hoisted_37 = { colspan: "2" }
const _hoisted_38 = {
  key: 0,
  class: "resources-list"
}
const _hoisted_39 = ["href"]
const _hoisted_40 = { class: "resource-content" }
const _hoisted_41 = { class: "resource-icon" }
const _hoisted_42 = ["alt", "src"]
const _hoisted_43 = { class: "resource-info" }
const _hoisted_44 = { class: "resource-name" }
const _hoisted_45 = { class: "resource-type" }
const _hoisted_46 = {
  key: 1,
  class: "empty-resources"
}
const _hoisted_47 = { class: "svg-icon svg-icon-3 svg-icon-gray-400 mb-3" }
const _hoisted_48 = { key: 3 }
const _hoisted_49 = {
  colspan: "2",
  class: "text-gray-400 pb-5"
}
const _hoisted_50 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_51 = { key: 4 }
const _hoisted_52 = { colspan: "2" }
const _hoisted_53 = { class: "overflow-auto pb-5" }
const _hoisted_54 = { class: "d-flex flex-row bd-highlight mb-3 row" }
const _hoisted_55 = { class: "text-gray-400 w-250px" }
const _hoisted_56 = { class: "text-gray-800" }
const _hoisted_57 = { class: "text-gray-400 w-250px" }
const _hoisted_58 = { class: "text-gray-800" }
const _hoisted_59 = { class: "text-gray-400" }
const _hoisted_60 = { class: "subject-tags" }
const _hoisted_61 = { class: "d-flex gap-2" }
const _hoisted_62 = { class: "text-gray-400" }
const _hoisted_63 = { class: "subject-tags" }
const _hoisted_64 = { class: "d-flex gap-2" }
const _hoisted_65 = { class: "text-gray-400" }
const _hoisted_66 = { class: "text-gray-400" }
const _hoisted_67 = { colspan: "2" }
const _hoisted_68 = { class: "ms-10" }
const _hoisted_69 = {
  colspan: "2",
  class: "text-gray-400 pb-5"
}
const _hoisted_70 = { colspan: "2" }
const _hoisted_71 = { class: "resources-list" }
const _hoisted_72 = { class: "resource-content" }
const _hoisted_73 = { class: "resource-icon" }
const _hoisted_74 = { class: "resource-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/library'))),
          class: _normalizeClass(["me-4 cursor-pointer icon-hover-effect", { 'rtl-icon': _ctx.locale === 'ar' }])
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, {
              src: "media/icons/duotune/arrows/arr063.svg",
              class: "svg-icon-black"
            })
          ])
        ], 2),
        _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("document.infoDocument")), 1)
      ]),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: `/document/${_ctx.documentID}/edit`,
              class: "btn btn-light-primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("document.edit")), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args))),
              class: "btn btn-light-danger mx-5"
            }, _toDisplayString(_ctx.t("document.delete")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("table", _hoisted_11, [
              (!_ctx.loading)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.document.coverPicture)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
                          _createElementVNode("td", _hoisted_13, [
                            _createElementVNode("span", _hoisted_14, [
                              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen006.svg" })
                            ]),
                            _createTextVNode(" " + _toDisplayString(_ctx.t("document.coverPicture")) + " : ", 1)
                          ]),
                          _createElementVNode("td", _hoisted_15, [
                            _createElementVNode("img", {
                              src: _ctx.document.coverPicture.url,
                              alt: "Cover Picture",
                              class: "w-100px h-100px rounded"
                            }, null, 8, _hoisted_16)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_17, [
                        _createElementVNode("span", _hoisted_18, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen005.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("document.title")) + " : ", 1)
                      ]),
                      _createElementVNode("td", _hoisted_19, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.document.title), 1)
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_20, [
                        _createElementVNode("span", _hoisted_21, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen003.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("document.subject")) + " : ", 1)
                      ]),
                      _createElementVNode("td", _hoisted_22, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.document.subject, (subject, index) => {
                          return (_openBlock(), _createBlock(_component_el_tag, {
                            key: index,
                            class: "subject-tag",
                            effect: "light",
                            round: "",
                            size: "small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(subject.name), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_23, [
                        _createElementVNode("span", _hoisted_24, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen003.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("course.level")) + " : ", 1)
                      ]),
                      _createElementVNode("td", _hoisted_25, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.document.level, (level, index) => {
                          return (_openBlock(), _createBlock(_component_el_tag, {
                            key: index,
                            class: "subject-tag",
                            effect: "light",
                            type: "info",
                            round: "",
                            size: "small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getLevelNames(level)), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_26, [
                        _createElementVNode("span", _hoisted_27, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen014.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("course.publicationDate")) + " : ", 1)
                      ]),
                      _createElementVNode("td", _hoisted_28, _toDisplayString(_ctx.moment(_ctx.document.createdAt).format("DD/MM/YYYY, H:mm")), 1)
                    ]),
                    (_ctx.document.desc)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("tr", _hoisted_29, [
                            _createElementVNode("td", _hoisted_30, [
                              _createElementVNode("span", _hoisted_31, [
                                _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                              ]),
                              _createTextVNode(" " + _toDisplayString(_ctx.t("course.description")) + " : ", 1)
                            ])
                          ]),
                          _createElementVNode("tr", null, [
                            _createElementVNode("td", _hoisted_32, [
                              _createElementVNode("div", {
                                innerHTML: _ctx.document.desc,
                                class: "ms-10"
                              }, null, 8, _hoisted_33)
                            ])
                          ])
                        ], 64))
                      : _createCommentVNode("", true),
                    (_ctx.document.files.length != 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_34, [
                          _createElementVNode("td", _hoisted_35, [
                            _createElementVNode("span", _hoisted_36, [
                              _createVNode(_component_inline_svg, { src: "media/icons/duotune/files/fil003.svg" })
                            ]),
                            _createTextVNode(" " + _toDisplayString(_ctx.t("document.resources")) + " : ", 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_37, [
                        (_ctx.document.files.length != 0 || _ctx.document.photos.length != 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.document.files, (file) => {
                                return (_openBlock(), _createElementBlock("a", {
                                  key: file,
                                  href: _ctx.getFileLink(file),
                                  target: "_blank",
                                  class: "resource-item"
                                }, [
                                  _createElementVNode("div", _hoisted_40, [
                                    _createElementVNode("div", _hoisted_41, [
                                      _createElementVNode("img", {
                                        alt: _ctx.getShortFileName(file),
                                        src: `media/files/${_ctx.getFileIconName(file)}.png`
                                      }, null, 8, _hoisted_42)
                                    ]),
                                    _createElementVNode("div", _hoisted_43, [
                                      _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.getFileName(file)), 1),
                                      _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.getFileIconName(file).toUpperCase()), 1)
                                    ])
                                  ])
                                ], 8, _hoisted_39))
                              }), 128))
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_46, [
                              _createElementVNode("span", _hoisted_47, [
                                _createVNode(_component_inline_svg, { src: "media/icons/duotune/files/fil024.svg" })
                              ]),
                              _createTextVNode(" " + _toDisplayString(_ctx.t("document.noResources")), 1)
                            ]))
                      ])
                    ]),
                    (_ctx.document.photos.length != 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_48, [
                          _createElementVNode("td", _hoisted_49, [
                            _createElementVNode("span", _hoisted_50, [
                              _createVNode(_component_inline_svg, { src: "media/icons/duotune/files/fil003.svg" })
                            ]),
                            _createTextVNode(" " + _toDisplayString(_ctx.t("course.photos")) + " : ", 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.document.photos.length != 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_51, [
                          _createElementVNode("td", _hoisted_52, [
                            _createElementVNode("div", _hoisted_53, [
                              _createElementVNode("div", _hoisted_54, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.document.photos, (photo) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "p-2 bd-highlight col-2",
                                    key: photo
                                  }, [
                                    _createVNode(_component_el_image, {
                                      style: {"width":"150px","height":"150px"},
                                      src: photo,
                                      "preview-src-list": _ctx.document.photos,
                                      "initial-index": 0,
                                      fit: "cover"
                                    }, null, 8, ["src", "preview-src-list"])
                                  ]))
                                }), 128))
                              ])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_55, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"150px"}
                        })
                      ]),
                      _createElementVNode("td", _hoisted_56, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "image",
                          style: {"width":"100px","height":"100px"}
                        })
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_57, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"120px"}
                        })
                      ]),
                      _createElementVNode("td", _hoisted_58, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"200px"}
                        })
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_59, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"100px"}
                        })
                      ]),
                      _createElementVNode("td", _hoisted_60, [
                        _createElementVNode("div", _hoisted_61, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
                            return _createVNode(_component_el_skeleton_item, {
                              key: i,
                              variant: "button",
                              style: {"width":"80px","height":"27px","border-radius":"13.5px"}
                            })
                          }), 64))
                        ])
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_62, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"80px"}
                        })
                      ]),
                      _createElementVNode("td", _hoisted_63, [
                        _createElementVNode("div", _hoisted_64, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                            return _createVNode(_component_el_skeleton_item, {
                              key: i,
                              variant: "button",
                              style: {"width":"90px","height":"27px","border-radius":"13.5px"}
                            })
                          }), 64))
                        ])
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_65, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"140px"}
                        })
                      ]),
                      _createElementVNode("td", null, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"160px"}
                        })
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_66, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"120px"}
                        })
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_67, [
                        _createElementVNode("div", _hoisted_68, [
                          _createVNode(_component_el_skeleton, {
                            rows: 3,
                            animated: ""
                          })
                        ])
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_69, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "text",
                          style: {"width":"100px"}
                        })
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_70, [
                        _createElementVNode("div", _hoisted_71, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
                            return _createElementVNode("div", {
                              key: i,
                              class: "resource-item"
                            }, [
                              _createElementVNode("div", _hoisted_72, [
                                _createElementVNode("div", _hoisted_73, [
                                  _createVNode(_component_el_skeleton_item, {
                                    variant: "image",
                                    style: {"width":"38px","height":"38px"}
                                  })
                                ]),
                                _createElementVNode("div", _hoisted_74, [
                                  _createVNode(_component_el_skeleton_item, {
                                    variant: "text",
                                    style: {"width":"70%"}
                                  }),
                                  _createVNode(_component_el_skeleton_item, {
                                    variant: "text",
                                    style: {"width":"40%"}
                                  })
                                ])
                              ])
                            ])
                          }), 64))
                        ])
                      ])
                    ])
                  ], 64))
            ])
          ])
        ])
      ])
    ])
  ]))
}